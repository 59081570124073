body {
  margin: 0;
  padding: 0;
  font-family: "Tahoma", Geneva, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: gainsboro;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.titleBar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  /* text-align: center; */
  padding: 20px;
  justify-content: center;
  width: 100%;
  text-transform: lowercase;
  /* font-weight: bold; */
  /* -webkit-text-stroke: 1px white; */
  color: slategray;
  font-size: 1.5em;
  /* height: 10vh; */
  min-height: auto;
  background-color: whitesmoke;
  box-shadow: 0px 0px 10px grey;
}

.link {
  text-decoration: none;
  color: slategray;
}

.outergrid {
  display: flex;
  flex-direction: row wrap;
  justify-content: center;
  justify-self: center;
  margin: 40px 10px 10px 10px;
}


.smallProjects {
  display: flex;
  flex-direction: column;
  width: 33vw;
  margin-right: 10px;
}

.largeProjects {
  display: flex;
  flex-direction: column;
  width: 60vw;
  max-width: 900px;
}

@media screen and (max-width: 600px) {
  .outergrid {
    flex-direction: column;
  }
  
  .smallProjects, .largeProjects {
    width: 100%;
  }
}

.project {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: whitesmoke;
  border-radius: 15px;
  box-shadow: 2px 3px 7px lightgray;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 10px;
  padding-bottom: 20px;
}

.title {
  color: tomato;
  padding: 10px;
  text-transform: uppercase;
  font-size: .9em;
}

.post {
  width: 100%;
  background: white;
  margin: 0px 0px 10px 0px;
}

.text {
  padding: 15px;
}

.date {
  display: flex;
  padding: 15px;
  font-weight: bold;
  font-size: .8em;
  justify-content: flex-end;
  color: slategray;
  margin: 0px;

}

.image {
  width: 100%;
  height: auto;
}
